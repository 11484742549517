import EnumPeriodForAnalytic from '../../../../common/models/enums/EnumPeriodForAnalytic';

export default class EndpointUsersStatisticRequest  {
	EndpointId: number;
	FromDate: Date;
	ToDate: Date;
	Period: EnumPeriodForAnalytic;
	constructor(obj?: Partial<EndpointUsersStatisticRequest>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
